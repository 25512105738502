@import '../theme.scss';

.textInput,
.textareaInput {
  &.invalid {
    input,
    textarea {
      border-color: $color-red;
    }
  }
}
